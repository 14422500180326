import React, { useEffect, useState } from 'react';
import mapImage from '../img/map.png';
import BottomNavBar from '../components/navbar';
import Cookies from 'js-cookie';

const StatisticsPage = () => {
    const [rentHistory, setRentHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRentHistory = async () => {
        const userId = Cookies.get('userId');
        try {
            const response = await fetch(`https://bot.millatumidi.uz/bikes/rent/history?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'bypass-tunnel-reminder': 'true',
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let data = await response.json();
            data = data.reverse();
            setRentHistory(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch rent history:', error.message);
            setError(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRentHistory();
    }, []);

    const completeRide = async () => {
        const userId = Cookies.get('userId');
        try {
            const response = await fetch(`https://bot.millatumidi.uz/users/complete/rent?userId=${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Failed to mark ride as complete');
            }
            fetchRentHistory();
        } catch (error) {
            console.error('Error completing ride:', error.message);
            setError(error.message);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return '--.--';
        }
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleString('uz-Latn-UZ', options);
    };

    return (
        <div className="px-4 py-2 flex flex-col h-screen">
            <h1 className="text-center text-2xl font-bold mb-6">Statistics</h1>
            <div className="my-4">
                <img src={mapImage} alt="Route map" className="w-full max-h-64 object-cover rounded-lg shadow-lg" />
            </div>

            {error && <p className="text-red-500 text-center font-semibold">{error}</p>}
            {isLoading ? (
                <p className="text-center font-semibold text-gray-500">Loading ride data...</p>
            ) : rentHistory.length > 0 ? (
                <div className="flex-grow overflow-y-auto">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200 text-sm">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                        Start Time
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                        End Time
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                        Price
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                        Complete
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {rentHistory.map((ride, index) => (
                                    <tr key={index} className="hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                                            {formatDate(ride.startTime)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                                            {formatDate(ride.endTime)}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-gray-700">
                                            {ride.totalCharge || '0'} UZS
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button
                                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                onClick={() => completeRide(ride.userId)}>
                                                Complete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="text-center text-gray-500">No rides available.</p>
            )}
            <BottomNavBar/>
        </div>
    );
};

export default StatisticsPage;
