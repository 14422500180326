import React from "react";
import { Routes, Route } from "react-router-dom";
import 'leaflet/dist/leaflet.css';
import Login from "../components/auth/login";
import Register from "../components/auth/register";
import MapComponent from "../components/main/map-main";
import StatisticsPage from "../components/statistics";
import ProfilePage from "../components/profile";
import Admin from "../components/admin/admin"

function Index() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/main" element={<MapComponent />} />
                <Route path="/statistics" element={<StatisticsPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/admin" element={<Admin />} />
            </Routes>
        </>
    );
}

export default Index;