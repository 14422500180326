import React, { useState, useEffect } from 'react';

const AdminSinglePage = () => {
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        balance: '',
        passportFilePath: '',
        passportVerified: '',
    });
    const [editing, setEditing] = useState(false);
    const [passportFile, setPassportFile] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('https://bot.millatumidi.uz/users/all', {
                    headers: {
                        'Content-Type': 'application/json',
                        'bypass-tunnel-reminder': 'true',
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                data.sort((a, b) => a.id - b.id);
                setUsers(data);
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        };
        fetchUsers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const updateUserData = async () => {
        const queryParams = {
            id: formData.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phone,
            email: formData.email,
            password: formData.password,
            balance: formData.balance,
            isPassportVerified: formData.passportVerified ? 'true' : 'false',
        };

        const url = `https://bot.millatumidi.uz/users?${toQueryString(queryParams)}`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
            });
            const responseBody = await response.text();
            if (!response.ok) {
                throw new Error(`Failed to update user: ${responseBody}`);
            }
            return JSON.parse(responseBody);
        } catch (error) {
            console.error('Failed to update user:', error);
            return null;
        }
    };

    const toQueryString = (params) => {
        const query = new URLSearchParams(params);
        return query.toString();
    };


    const handleSave = async () => {
        if (editing) {
            const updatedUser = await updateUserData();
            if (updatedUser) {
                setUsers(users.map((user) => (user.id === formData.id ? updatedUser : user)));
            }
        }
        setFormData({
            id: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            password: '',
            balance: '',
            passportFilePath: '',
            passportVerified: '',
        });
        setPassportFile(null);
        setEditing(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave();
    };

    const editUser = (user) => {
        setFormData({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            email: user.email,
            password: user.password,
            balance: user.balance,
            passportFilePath: user.passportFilePath,
            passportVerified: user.passportVerified
        });
        setEditing(true);
    };

    const viewPassport = (passportFilePath) => {
        if (passportFilePath) {
            const fileUrl = `https://bot.millatumidi.uz/users/files?filePath=${encodeURIComponent(passportFilePath)}`;
            window.open(fileUrl, '_blank');
        } else {
            alert('No passport file available.');
        }
    };

    return (
        <div className="p-4 max-w-5xlxl mx-auto">
            <h1 className="text-2xl font-bold text-center mb-6">Admin Panel</h1>
            <form onSubmit={handleSubmit} className="space-y-4 mb-8">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                    />
                    <input
                        type="text"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required={!editing}
                    />
                    <input
                        type="text"
                        name="balance"
                        placeholder="Balance"
                        value={formData.balance}
                        onChange={handleChange}
                        className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                    />
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-700">Passport Verified</label>
                        <select
                            name="passportVerified"
                            value={formData.passportVerified}
                            onChange={handleChange}
                            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="w-full md:w-auto px-6 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-200"
                    >
                        {editing ? 'Update' : 'Save'}
                    </button>
                </div>
            </form>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-gray-100">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User ID</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Full Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Balance</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Passport Verified</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((user) => (
                        <tr key={user.id} className="hover:bg-gray-50 transition duration-150">
                            <td className="px-6 py-4 whitespace-nowrap">{user.id.toString().padStart(7, '0')}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{`${user.firstName} ${user.lastName}`}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{user.phone}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{user.balance}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{user.passportVerified ? 'Yes' : 'No'}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <button onClick={() => editUser(user)}
                                        className="px-4 py-2 mr-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600">
                                    Edit
                                </button>
                                {user.passportFilePath && (
                                    <button onClick={() => viewPassport(user.passportFilePath)}
                                            className="px-4 py-2 text-sm text-white bg-green-500 rounded hover:bg-green-600">
                                        View Passport
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminSinglePage;
