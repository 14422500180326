import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './router';
import 'leaflet/dist/leaflet.css';
import './style/index.css'

const App = () => {
    return (
        <>
            <Router>
                <Routing />
            </Router>
        </>
    )
}

export default App;
