import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logoutIcon from '../img/log.svg';
import { MapIcon, UserIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const BottomNavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg">
            <div className="flex justify-around p-4">
                <button
                    className="flex flex-col items-center"
                    onClick={() => navigate('/main')}
                >
                    <MapIcon
                        className="h-6 w-6 mb-1"
                        style={{
                            color: isActive('/main') ? '#5200FF' : '#6B7280',
                        }}
                    />
                    <span
                        className="text-xs"
                        style={{color: isActive('/main') ? '#5200FF' : '#6B7280'}}
                    >
                        Map
                    </span>
                </button>
                <button
                    className="flex flex-col items-center"
                    onClick={() => navigate('/profile')}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mb-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        style={{
                            color: isActive('/profile') ? '#5200FF' : '#6B7280', // Active color #5200FF, otherwise gray
                        }}
                    >
                    <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 12c2.28 0 4-1.72 4-4s-1.72-4-4-4-4 1.72-4 4 1.72 4 4 4zm0 2c-4.41 0-8 1.79-8 4v2h16v-2c0-2.21-3.59-4-8-4z"/>
                    </svg>
                    <span
                        className="text-xs"
                        style={{color: isActive('/profile') ? '#5200FF' : '#6B7280'}}
                    >
                        Profile
                    </span>
                </button>

                <button
                    className="flex flex-col items-center"
                    onClick={() => navigate('/statistics')}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mb-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        style={{
                            color: isActive('/statistics') ? '#5200FF' : '#6B7280', // Active color #5200FF, otherwise gray
                        }}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M3 6h18M3 18h18"/>
                    </svg>
                    <span
                        className="text-xs"
                        style={{color: isActive('/statistics') ? '#5200FF' : '#6B7280'}}
                    >
                        Statistics
                    </span>
                </button>

                <button
                    className="flex flex-col items-center"
                    onClick={() => navigate('/login')}
                >
                    <img
                        src={logoutIcon}
                        alt="Logout"
                        className="h-6 w-6 mb-1"
                        style={{
                            filter: isActive('/') ? 'grayscale(0%)' : 'grayscale(100%)',
                            color: isActive('/') ? '#5200FF' : '#6B7280',
                        }}
                    />
                    <span
                        className="text-xs"
                        style={{color: isActive('/') ? '#5200FF' : '#6B7280'}}
                    >
                        Logout
                    </span>
                </button>
            </div>
        </div>
    );
};

export default BottomNavBar;
