import React, {useEffect, useState} from 'react';
import BottomNavBar from '../components/navbar';
import Cookies from 'js-cookie';

const ProfilePage = () => {
    const [user, setUser] = useState(null); // Single user expected

    useEffect(() => {
        const userId = Cookies.get('userId');
        const fetchUser = async () => {
            try {
                const response = await fetch(`https://bot.millatumidi.uz/users?id=${userId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'bypass-tunnel-reminder': 'true',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUser(data); // Assuming the response is a single user object
                } else {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        };
        fetchUser();
    }, []);

    return (
        <div className="p-4 space-y-6">
            <h1 className="text-center text-lg font-bold mb-4">Profil</h1>

            {/* User Avatar */}
            <div className="flex justify-center mb-4">
                <img
                    src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=" // Replace with user's actual avatar image URL
                    alt="User Avatar"
                    className="w-36 h-36 rounded-full"
                />
            </div>

            {user ? (
                <>
                    <div className="bg-white p-4 rounded-lg shadow-lg space-y-2">
                        <div className="flex justify-between">
                            <p className="text-gray-500">Foydalanuvchi ID Raqami</p>
                            <p className="font-medium">{user.id.toString().padStart(7, '0')}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-500">To'liq ismi</p>
                            <p className="font-medium">{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-500">Email</p>
                            <p className="font-medium">{user.email}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-500">Telefon raqami</p>
                            <p className="font-medium">{user.phone}</p>
                        </div>
                    </div>

                    <div className="bg-gradient-to-r from-blue-400 to-blue-300 p-4 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-white text-sm">Hamyon</p>
                                <p className="text-white text-3xl font-bold">{`${user.balance} UZS`}</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <p className="text-center">No user data found or loading...</p>
            )}

            <div className="bg-gradient-to-r from-red-400 to-red-300 p-4 rounded-lg shadow-lg">
                <div className="flex justify-between items-center mb-4">
                    <p className="text-white text-lg">Bank Kartasi</p>
                    <p className="text-white text-lg">Humo</p>
                </div>
                <div className="flex justify-between items-center">
                    <p className="text-white">**** **** **** 2107</p>
                    <p className="text-white">08/25</p>
                </div>
            </div>
            <BottomNavBar />
        </div>
    );
};

export default ProfilePage;
