import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import logoImage from '../../img/logo.png';
import Cookies from 'js-cookie';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const adminEmail = 'admin@mail.com';
    const adminPassword = 'root';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Admin check
        if (email === adminEmail && password === adminPassword) {
            setSuccess('Admin kirish muvaffaqiyatli bajarildi!');
            setTimeout(() => {
                navigate('/admin');
            }, 1500);
            return;
        }

        try {
            const response = await fetch(`https://bot.millatumidi.uz/users/login?email=${email}&password=${password}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error("Kirishda xatolik yuz berdi");
            }

            const userId = await response.json();
            setSuccess('Kirish muvaffaqiyatli bajarildi!');
            // localStorage.setItem('userId', userId);
            Cookies.set('userId', userId, { expires: 1, path: '/' });

            setTimeout(() => {
                navigate('/main');
            }, 1500);
        } catch (error) {
            setError(error.message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <section className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-sm px-6 py-8 mx-auto bg-white">
                <div className="flex items-center justify-center mb-6">
                    <img className="max-w-24 h-24" src={logoImage} alt="logo" />
                </div>
                <h1 className="mb-4 text-xl font-bold text-center text-gray-900">
                    Ilovaga Kirish
                </h1>
                {error && (
                    <div className="mb-4 text-sm font-medium text-center text-white bg-red-500 rounded-lg px-4 py-2">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mb-4 text-sm font-medium text-center text-white bg-green-500 rounded-lg px-4 py-2">
                        {success}
                    </div>
                )}
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-900">Login</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 placeholder-gray-500 bg-gray-50 border border-gray-300 rounded-lg focus:border-primary-600 focus:ring-primary-600"
                            placeholder="test@mail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-900">Parol</label>
                        <div className="relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                className="block w-full px-4 py-2 mt-2 text-gray-900 placeholder-gray-500 bg-gray-50 border border-gray-300 rounded-lg focus:border-primary-600 focus:ring-primary-600"
                                placeholder="••••••••"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                            >
                                {showPassword ? (
                                    <EyeSlashIcon className="w-5 h-5" />
                                ) : (
                                    <EyeIcon className="w-5 h-5" />
                                )}
                            </button>
                        </div>
                    </div>
                    <button type="submit" style={{ backgroundColor: "#5200FF" }}
                            className="w-full px-4 py-2 text-sm font-medium text-center text-white bg-blue-500 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-500">
                        Davom etish
                    </button>
                    <p className="text-sm text-center text-gray-500">
                        Davom etish uchun <a href="#" style={{ color: "#5200FF" }}>Qonun qoidalar va Foydalanuvchi ma’lumotlari</a> to’g’risidagi qoidalarni qa’bul qilaman
                    </p>
                </form>
            </div>
        </section>
    );
};

export default Login;
