import React, { useState, useEffect } from 'react';
import BottomNavBar from '../navbar';
import scanImage from '../../img/scan.svg';
import locationImage from '../../img/location.svg';
import QrScanner from 'react-qr-scanner'; // Import the QR code scanner component
import Modal from '../modal';
import Cookies from "js-cookie";

const MapComponent = () => {
    const [location, setLocation] = useState({ lat: 35.1595, lng: 129.0604 }); // Default coordinates
    const [locationInfo, setLocationInfo] = useState('Loading location...');
    const [scanning, setScanning] = useState(false); // State to manage scanning mode
    const [scanResult, setScanResult] = useState(null); // State to store scan result
    const [isOpen, setIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [mediaStream, setMediaStream] = useState(null); // State to hold the media stream
    const [map, setMap] = useState(null); // Store the map object for updates
    const [bikeLocation, setBikeLocation] = useState(null); // State to store bike location

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                setLocation(pos);
                setLocationInfo(`Lat: ${pos.lat}, Lng: ${pos.lng}`);
                initializeMap(pos);
            }, () => {
                setLocationInfo('Unable to retrieve your location');
            });
        } else {
            setLocationInfo('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        fetchBikeLocation();
        fetchBikeLocation2();
        fetchBikeLocation3();
        fetchBikeLocation4();
        fetchBikeLocation5();
        fetchBikeLocation6();
        fetchBikeLocation7();
        fetchBikeLocation8();
        fetchBikeLocation9();
        fetchBikeLocation10();
        fetchBikeLocation11();
        fetchBikeLocation12();
        fetchBikeLocation13();
        fetchBikeLocation14();
        fetchBikeLocation15();
        fetchBikeLocation16();
        fetchBikeLocation17();
    }, []);

    useEffect(() => {
        if (map && bikeLocation) {
            addBikeMarker(bikeLocation);
        }
    }, [bikeLocation, map]);

    useEffect(() => {
        initializeMap(location);
    }, []);

    const fetchBikeLocation = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400196` , {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation2 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400153 `, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation3 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400142 `, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation4 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400169 `, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation5 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400118`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation6 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400186`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation7 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400189`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation8 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400184`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation9 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400114`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation10 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400164`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation11 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400124`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation12 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400141`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation13 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400132`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation14 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400103`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation15 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400123`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation16 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400161`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };

    const fetchBikeLocation17 = () => {
        fetch(`https://bot.millatumidi.uz/bikes?deviceKey=074241400186`, {
            headers: {
                'Content-Type': 'application/json',
                'bypass-tunnel-reminder': 'true',
            },
        })
            .then(response => response.json())
            .then(data => {
                setBikeLocation({ lat: data.Lat, lng: data.Lng });
            })
            .catch(error => {
                console.error('Failed to fetch bike location:', error);
            });
    };


    const handleScan = (data) => {
        const userId = Cookies.get('userId');
        if (data) {
            setScanResult(data.text);
            setScanning(false);
            stopCamera(); // Stop the camera when scanning is done
            setIsOpen(true);

            const apiUrl = `https://bot.millatumidi.uz/bikes/rent?deviceKey=${encodeURIComponent(data.text)}&userId=${userId}`;
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'bypass-tunnel-reminder': 'true',
                }
            })
                .then(() => {
                    // Since no-cors doesn't allow reading the response, assume success if fetch completes
                    setModalMessage(`Bike rented successfully! Device Key: ${data.text}`);
                })
                .catch(() => {
                    // Handle any errors that occur during the fetch
                    setModalMessage('Failed to rent the bike. Please try again.');
                });
        }
    };

    const stopCamera = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(null);
        }
        setScanning(false);
    };

    const initializeMap = (pos) => {
        const map = new window.google.maps.Map(document.getElementById("map"), {
            center: pos,
            zoom: 15,
            disableDefaultUI: true,
        });
        setMap(map);

        const marker = new window.google.maps.Marker({
            position: pos,
            map: map,
            title: 'Your Location',
            icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 12, // Size
                fillColor: "#FF0000", // Red
                fillOpacity: 1,
                strokeWeight: 2
            }
        });

        // Resize listener for mobile responsiveness
        window.addEventListener('resize', () => {
            map.setCenter(pos);
        });
    };

    const addBikeMarker = (bikeLoc) => {
        new window.google.maps.Marker({
            position: bikeLoc,
            map: map,
            title: 'Bike Location',
            icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 12,
                fillColor: "#0000FF", // Blue for bike location
                fillOpacity: 1,
                strokeWeight: 2
            }
        });
    };

    const handleError = (err) => {
        console.error(err);
        alert('Error accessing the camera. Please check your device settings.');
    };

    const previewStyle = {
        height: "100%",
        width: "100%",
    };

    const handleScannerOpen = () => {
        setScanning(true);
    };

    return (
        <div className="relative h-screen w-full">
            <div id="map" className="h-full w-full" />

            <div className="absolute top-6 left-0 right-0 mx-4">
                <div className="flex items-center bg-white p-3 rounded-lg">
                    <div className="p-2 rounded-full">
                        <img src={locationImage} alt="Scan" className="h-8 w-8"/>
                    </div>
                    <div className="ml-4 text-gray-700">
                        <p className="text-sm md:text-base">Current Location</p>
                        <p className="text-xs md:text-sm">{locationInfo}</p>
                    </div>
                </div>
            </div>

            {/* QR Scanner Button */}
            <div className="absolute bottom-10 right-4 flex flex-col space-y-4 mb-12">
                <button
                    className="p-4 rounded-full shadow-lg"
                    onClick={handleScannerOpen} // Open the scanner
                >
                    <img src={scanImage} alt="Scan" className="h-10 w-10" />
                </button>
            </div>

            {scanning && (
                <div className="absolute inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
                        <QrScanner
                            delay={300}
                            style={previewStyle}
                            onError={handleError}
                            onScan={handleScan}
                            constraints={{
                                video: {
                                    facingMode: { ideal: "environment" } // Use the back camera if available, otherwise fallback to any available camera
                                }
                            }}
                        />
                        <div className="flex justify-center mt-4">
                            <button
                                onClick={() => setScanning(false)}
                                className="bg-red-500 text-white px-4 py-2 rounded-lg mb-4"
                            >
                                Close Scanner
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Modal isOpen={isOpen} message={modalMessage} onClose={() => setIsOpen(false)} />
            <BottomNavBar />
        </div>
    );
};

export default MapComponent;
